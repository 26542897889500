<template>
  <v-card class="pr-5 pl-10" elevation="0">
    <v-breadcrumbs
      class="ml-10 ma-0 pa-0 mb-5"
      :items="links"
      divider=">"
      large
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href">
          <p class="caption ma-0" style="color: #8739eb; text-decoration: none">
            {{ item.text }}
          </p>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card-title class="ml-8 mb-3 ma-0 pa-0">
      <div class="d-flex">
        <v-btn icon @click="close"><v-icon small>mdi-arrow-left</v-icon></v-btn>
        <div class="d-flex flex-column align-start mt-1">
          <p class="text-body-1 ma-0 font-weight-bold">{{ showName() }}</p>
          <div class="d-flex align-center"></div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    
    <v-card elevation="0" class="pl-5">
      <v-alert
      :value="alert"
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="userEdited"
      >
      <p class="caption pa-0 ma-0">¡El usuario se modificó con éxito!</p>
    </v-alert>
      <v-card-title class="d-flex align-center mt-5 mb-5 ml-5">
        <p class="text-body-1 ma-0 font-weight-bold mr-8">Datos generales</p>
        <v-btn
          small
          class="text-none caption"
          rounded
          outlined
          color="#8739EB"
          elevation="0"
          v-if="!edit"
          @click="edit = !edit"
        >
          <v-icon class="mr-1" small color="#8739EB">mdi-pencil-outline</v-icon>
          Editar usuario
        </v-btn>
        <v-btn
          small
          class="text-none caption mr-8"
          rounded
          outlined
          color="#8739EB"
          elevation="0"
          v-if="edit"
          @click="(edit = !edit), updateUser(), setNameTitle()"
        >
          <v-icon class="mr-1" small color="#8739EB">mdi-content-save</v-icon>
          Guardar cambios
        </v-btn>
        <v-btn
          small
          class="text-none caption"
          rounded
          outlined
          color="#8739EB"
          elevation="0"
          v-if="edit"
          @click="(edit = !edit), cancel()"
        >
          <v-icon class="mr-1" small color="#8739EB">mdi-close-circle</v-icon>
          Cancelar
        </v-btn>
      </v-card-title>
      <v-card elevation="0" class="mb-16 ml-10 d-flex justify-center">
        <v-card class="d-flex flex-column" width="100%" elevation="0">
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-account-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Nombre</p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="name"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-account-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Apellido</p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="lastName"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-email-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Correo</p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="email"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small
                  >mdi-card-account-mail-outline</v-icon
                >
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Correo de Contacto
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="emailContact"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-shopping-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Area Corporativa
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="area"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-phone-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">Telefono</p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-text-field
                :filled="edit"
                rounded
                dense
                hide-details
                class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light"
                v-model="phoneNumber"
                :readonly="!edit"
              >
              </v-text-field>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mt-5 mb-5 row">
            <v-col cols="4">
              <v-row>
                <v-icon color="#8739EB" small>mdi-account-group-outline</v-icon>
                <p class="caption ma-0 font-weight-bold ml-6 mr-16">
                  Tipo de usuario
                </p>
              </v-row>
            </v-col>
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0 text-left"
              style="width: 12rem"
              :loading="loading"
            >
              <v-select
                :items="types"
                v-model="typeUser"
                :filled="edit"
                rounded
                hide-details
                class="mt-n4 mb-n4 caption ma-0 font-weight-light"
                :readonly="!edit"
                dense
                :append-icon="icon"
              ></v-select>
            </v-skeleton-loader>
          </div>
          <v-divider></v-divider>
        </v-card>
      </v-card>
    </v-card>
    <div v-if="usersStateLoading === true">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div
            class="modal"
            role="dialog"
            area-labelledby="modalTitle"
            aria-describedby="modalDesc"
          >
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear
                color="#6E80F8"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <!-- <v-progress-circular
              color="white"
              size="40"
              width="6"
              indeterminate
            ></v-progress-circular> -->
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import ReturnPage from "../../mixins/ReturnPage";
import LoadCard from "../../mixins/LoadCard";
import { mapGetters, mapActions, mapState } from "vuex";
import ENUM from "@/store/enums";

export default {
  mixins: [ReturnPage, LoadCard],
  data: () => ({
    edit: false,
    name: "",
    email: "",
    degree: "",
    city: "",
    phoneNumber: "",
    nameTitle: "",
    language: "Español",
    icon: "",
    lastName: "",
    types: ["Reclutador", "Admin"],
    typeUser: "",
    userType: "",
    area: "",
    emailContact: "",
    links: [
      {
        disabled: false,
        href: "/users",
      },
      {
        text: "Detalle Usuarios",
        disabled: false,
        disabled: "/users",
      },

      {
        text: "Detalle",
        disabled: true,
      },
    ],
    status: ["aceptado", "rechazado"],
    tab: null,
  }),
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    ...mapActions(["requestsUser", "updateUserById"]),
    showName() {
      return this.nameTitle;
    },
    setNameTitle() {
      this.nameTitle = this.name.concat(" ", this.lastName);
    },
    updateUser() {
      if (this.typeUser == "Admin") {
        this.userType = this.typeUser.toUpperCase();
      } else {
        this.userType = "RECRUITER";
      }
      const payload = {
        user_id: this.$route.params.id,
        user_area: this.area,
        user_company: this.getUserData.user_company,
        user_contact_email: this.emailContact,
        user_created_at: this.getUserData.user_created_at,
        user_first_name: this.name,
        user_is_active: this.getUserData.user_is_active,
        user_last_name: this.lastName,
        user_user_loging_attempts: this.getUserData.user_loging_attempts,
        user_login_email: this.email,
        user_phone_number: this.phoneNumber,
        user_type: this.userType,
        user_uid: this.getUserData.user_uid,
        user_zones: this.getUserData.user_zones,
      };
      this.updateUserById(payload);
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
    ...mapState({
      usersState: (state) => state.users.status,
    }),
    usersStateLoading() {
      return this.usersState === ENUM.LOADING;
    },
    userEdited(){
      return this.$store.state.users.editedUser
    }
  },
  created() {
    this.requestsUser(this.$route.params.id);
  },
  watch: {
    getUserData() {
      this.name = this.getUserData.user_first_name;
      this.nameTitle = this.getUserData.user_first_name.concat(
        " ",
        this.getUserData.user_last_name
      );
      this.lastName = this.getUserData.user_last_name;
      this.email = this.getUserData.user_login_email;
      this.phoneNumber = this.getUserData.user_phone_number;
      this.area = this.getUserData.user_area;
      this.emailContact = this.getUserData.user_contact_email;
      if (this.getUserData.user_type.toLowerCase() == "recruiter") {
        this.typeUser = "Reclutador";
      } else {
        this.typeUser = "Admin";
      }
    },
    userEdited() {
      return setTimeout(
        () => this.$store.commit("EDITED_USER_ALERT", false),
        3000
      );
    },
    
  },
  mounted() {},
  beforeUpdate() {},
  updated() {},
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
h4 {
  color: #fff;
  letter-spacing: 2px;
}
</style>
